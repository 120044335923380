
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <meta id="onboarding" title="Onboarding" />
    <h1>{`Onboarding`}</h1>
    <p>{`After a consultation call, if you agree to working with us, we onboard you. There are certain documents you need to upload before we can start your relocation process. These documents are important especially when we send offers on your behalf for the apartments. Therefore, in this section, we want to explain what kind of documents you need and how you should upload them.`}</p>
    <loom id="7bb6f1b878c0421c9d5283149e328abe" />
    <h2>{`Passport`}</h2>
    <p>{`We will need your passport in case you are moving alone or also the passport of your partner/spouse if you are moving as a couple.`}</p>
    <p><img parentName="p" {...{
        "src": "onboarding-passport.jpeg",
        "alt": "centered"
      }}></img></p>
    <p>{`It is important that the passport number is readable and the entire first page is visible. Therefore, uploading a copy of your passport as a PDF would be preferable.`}</p>
    <h2>{`Employment Contract`}</h2>
    <p>{`It is the most essential document for sending the offer. A few things to consider:`}</p>
    <ul>
      <li parentName="ul">{`The start date of the employment contract should not be a month later than your moving date to the Netherlands. So for example, if your employment contract starts in November, it is unlikely that your offer will get accepted for an apartment which is available from September.`}</li>
      <li parentName="ul">{`Your employment contract should be signed by both parties. If you have not signed your employment contract yet, you need to receive a letter of intent from your future employer.`}</li>
      <li parentName="ul">{`If your partner works for a foreign company, you should get an employer’s statement next to your partner’s employment contract. If a company does not want to sign an English document, they can sign it in the official language of the company then you can go to a notary for the translation and the seal.`}</li>
    </ul>
    <h2>{`Short Description & Image`}</h2>
    <p>{`Attaching to your offer a nice picture of yours (and your partner / family) with a personal introduction will double or triple your chances of getting the apartment.`}</p>
    <p>{`Your (and your partner's / family's) picture is very important for the landlord to have an idea about to whom they are renting the place. Knowing how you look, gives a comfortable feeling to the owner. For that reason, the image should look clear, preferably direct eye contact with the smile and without photoshop wizardry.`}</p>
    <gallery images={{
      'offer-photos/single-1.jpeg': 'Single',
      'offer-photos/single-2.jpeg': 'Single',
      'offer-photos/single-3.jpeg': 'Single',
      'offer-photos/couple-1.jpeg': 'Couple',
      'offer-photos/couple-2.jpeg': 'Couple',
      'offer-photos/couple-3.jpeg': 'Couple',
      'offer-photos/family-1.jpeg': 'Family',
      'offer-photos/family-2.jpeg': 'Family'
    }} />
    <p>{`In the short description please describe in one sentence why you (and your partner/family) are moving to the Netherlands and in one or two sentences shortly about your job, hobbies and why you will be a great tenant.`}</p>
    <h2>{`V-number:`}</h2>
    <p>{`V-number is your unique IND registration number. Every non-EU citizen should have a V-number and you receive this number after your MVV application at the IND has been approved. You can find your V-number on the top right corner of the letter you received by the IND.`}</p>
    <p>{`We need your V-number to schedule an appointment for `}<a parentName="p" {...{
        "href": "https://dox.relocify.nl/dox/essentials/bsn"
      }}>{`BSN`}</a>{` (municipal registration) for you and your family. Please provide us this number as soon as possible to have an appointment soon after the arrival.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;